import React, { useState, useEffect } from "react";
import podcastFeedParser, { Podcast, Episode } from "podcast-feed-parser";
import { SimpleImg } from "react-simple-img";
import moment from "moment";
import styled from "styled-components";

export function App() {
  const [feedUrl, setFeedUrl] = useState("");
  const [podcast, setPodcast] = useState<Podcast | undefined>();

  useEffect(() => {
    async function loadPodcast() {
      const p = await podcastFeedParser.getPodcastFromURL(feedUrl);
      setPodcast(p);
    }
    if (feedUrl.length > 0) loadPodcast();
  }, [feedUrl]);

  const bytesToMegaBytes = (bytes: number) => Math.round(bytes / (1024 * 1024));

  function renderEpisodesList(episodes: Episode[]) {
    return episodes.map((episode, index) => {
      return (
        <Grid
          key={index}
          columnWidth={"50px"}
          style={{ borderBottom: "2px soild #555" }}
        >
          <div>
            <SimpleImg
              height={50}
              width={50}
              src={episode.imageURL}
              alt="Cover image"
            />
          </div>
          <div>
            <h3 style={{ marginTop: 0 }}>{episode.title}</h3>
            <table>
              <tbody>
                <tr>
                  <td>
                    <strong>Link</strong>
                  </td>
                  <td>
                    <a href={episode.link}>{episode.link}</a>
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Size</strong>
                  </td>
                  <td>
                    {bytesToMegaBytes(parseInt(episode.enclosure.length, 10))}MB
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Type</strong>
                  </td>
                  <td>{episode.enclosure.type}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Enclosure</strong>
                  </td>
                  <td>{episode.enclosure.url}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Published</strong>
                  </td>
                  <td>
                    {moment(episode.pubDate).format("dddd, MMMM Do YYYY")}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Grid>
      );
    });
  }

  return (
    <div style={{ padding: "100px", height: "100%" }}>
      <div>
        <h1>PaRSSer</h1>
        <form>
          <input
            style={{ width: "500px", height: "50px" }}
            placeholder="Paste a feed URL here"
            onChange={(e) => setFeedUrl(e.target.value)}
          />
        </form>
      </div>
      <div>
        {podcast && (
          <Grid>
            <div>
              <img
                src={podcast.meta.imageURL}
                alt="Podcast cover"
                height="100"
                width="100"
              ></img>
            </div>
            <div>
              <h2 style={{ margin: 0 }}>{podcast.meta.title}</h2>
              <Byline>by {podcast.meta.author}</Byline>
              {podcast.meta.subtitle && <p>{podcast.meta.subtitle}</p>}
              <p>{podcast.meta.description}</p>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <strong>Feed last updated</strong>
                    </td>
                    <td style={{ margin: "50px" }}>
                      {moment(podcast.meta.lastUpdated).format(
                        "dddd, MMMM Do YYYY, h:mm:ss a"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Categories</strong>
                    </td>
                    <td style={{ margin: "50px" }}>
                      {podcast.meta.categories.join(", ")}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Link</strong>
                    </td>
                    <td style={{ margin: "50px" }}>
                      <a href={podcast.meta.link}>{podcast.meta.link}</a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Total episodes</strong>
                    </td>
                    <td style={{ margin: "50px" }}>
                      {podcast.episodes.length}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div>{renderEpisodesList(podcast.episodes)}</div>
            </div>
          </Grid>
        )}
      </div>
    </div>
  );
}

const Grid = styled.div<{ columnWidth?: string }>`
  display: grid;
  grid-template-columns: ${(props) => props.columnWidth || "100px"} 1fr;
  column-gap: 20px;
  row-gap: 20px;
  margin-top: 20px;
`;
const Byline = styled.p`
  margin-top: 3px;
  font-size: 0.8em;
  color: #555;
`;
